import React from "react"
import PropTypes from "prop-types"

const Layout = ({ children }) => {
  return (
    <>
      <header className="p-8 md:p-16 pb-0 md:pb-0">
        <h1 className="font-bold">HAVE A GOOD TIME</h1>
      </header>
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
